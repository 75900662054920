import React from "react"
import { Link } from "react-router-dom"

export default function DashboardPage() {
  return (
    <>
      <div style={{ fontSize: 36 }}>Protected page</div>
      <ul>
        <li>
          <Link to="/dashboard/invoices">Invoices</Link>
        </li>
        <li>
          <Link to="/">Return to index...</Link>
        </li>
      </ul>
    </>
  )
}
